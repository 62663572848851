import Container from '../../components/menu/Container';
import { useTranslation } from "react-i18next";

import {useEffect, useRef, useState } from 'react';
import postRelatorio from '../../service/postRelatorio';
import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
import { FiltrarRelatorio } from '../../interface/FiltrarRelatorio';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';
import { SortOrder } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import findClient from '../../service/findClient';



export default function FiltrarConsultaSinteticaPage() {
  const { t } = useTranslation();
  const [data, setData] = useState<Nullable<Date>>(null);
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(null);
  const msgs = useRef<Messages>(null);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [clients, setClients] = useState<any>([]);
  const initialized = useRef(false)
  const {state} = useGlobalState();
  const [agrupadoPor, setAgrupadoPor] = useState<string>("USUARIO");

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDate = (date:Nullable<Date>) => {
    if(date){
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('/');
    }
   
  }
  
  const salvarRelatorio = () => {
    if(data && dataFinal && dataFinal >= data){
      const filtrarRelatorio:FiltrarRelatorio ={tipoRelatorio:'CONSULTA_SINTETICO', tipoArquivo: 'PDF', filtro:{ agrupadoPor: agrupadoPor, idCliente: selectedClient ? selectedClient.code :  null, data:formatDate(data), dataFinal: formatDate(dataFinal) }};
      postRelatorio(filtrarRelatorio).then((data) => {
        if (msgs.current) {
          msgs.current?.clear();
          msgs.current?.show([
              { sticky: false, life: 10000, severity: 'success', summary: 'Sucesso', detail: data, closable: false }
             
          ]);
        }
      });
    }
    else{
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Data Inicial e Data Final Devem ser preenchidas com data Final Superior ou igual Data Inicial', closable: false }
         
      ]);
    }
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
   
      const lazyClient = {
        first: 0,
        rows: 99999999,
        page: 1,
        cliente: 'null',
        documento: 'null',
        codigoClienteErp: 'null',
        idFranquia : state.user.franquiaSelected?.id ? state.user.franquiaSelected?.id : null,
        sortField: 'nome',
        sortOrder: SortOrder.DESC
      }
      const clientes : any[] = [];
      
      findClient(lazyClient).then(data => {
        data.clientes.forEach( (c: { nome: string; id: string; }) => {
          clientes.push({name: c.nome, code:c.id})
          if(state.user.cliente && c.nome === state.user.cliente.nome){
            setSelectedClient({name:state.user.cliente.nome, code:c.id})
          }
        })
      })
      setClients(clientes);
    }
  },[])
  
  const endContent = (
    <React.Fragment>
       <div  style={{background:'transparent'}}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <label htmlFor="data" className="font-bold block mb-2 required">{t('dataInicial')} </label>
            <Calendar id='data' value={data} onChange={(e) => setData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <div className="flex-consulta justify-content-center">
              <div className="flex-consulta flex-column gap-3">
                <div className="flex-consulta align-items-center">
                  <RadioButton inputId="agrupadoPorUsuario" name="usuario" value="USUARIO" onChange={(e) => setAgrupadoPor(e.value)} checked={agrupadoPor === 'USUARIO'} />
                  <label htmlFor="agrupadoPorUsuario" className="ml-2">Agrupado por Usuário</label>
                </div>
                <div className="flex-consulta align-items-center">
                  <RadioButton inputId="agrupadoPorCliente" name="cliente" value="CLIENTE" onChange={(e) => setAgrupadoPor(e.value)} checked={agrupadoPor === 'CLIENTE'} />
                  <label htmlFor="agrupadoPorCliente" className="ml-2">Agrupado por CLiente</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  style={{background:'transparent', marginLeft:'10rem'}}>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap">
            <label htmlFor="dataFinal" className="font-bold block mb-2 required">{t('dataFinal')} </label>
            <Calendar id='dataFinal' value={dataFinal} onChange={(e) => setDataFinal(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
          </div>
        </div>
        <div className="grid">
          <div className="col p-fluid flex flex-wrap" style={{visibility: state.user.cliente ? 'hidden' : 'visible'}}>
            <label className="label-dados" style={{paddingLeft:'0'}}>{t('cliente')}</label>
            <Dropdown disabled={state.user.cliente} style={{width: '50%'}} value={selectedClient} onChange={(e) => setSelectedClient(e.value)} options={clients} optionLabel="name" placeholder="Selecione Cliente" 
                    filter className="md:w-14rem" />  
          </div>
        </div>
        
      </div>
 
    
      
      <Button style={{backgroundColor:'#183462', marginLeft:'5rem'}} label="Gerar" onClick={() => {salvarRelatorio()}}   />
    </React.Fragment>
);




  return (
    <Container>
      <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray"><i className="pi pi-filter" style={{ fontSize: '2.0rem' }}></i> {t('relatorioSinteticoDeConsultas')}</h1>
            </div>     
          </div>
      </div>
      <Messages ref={msgs} />
      <div className="card">
        <Toolbar start={endContent} />
      </div>
                
    </Container>
  )
}
