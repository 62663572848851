import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import React from "react";
import { TextField } from "../../../../components/TextField";
import styles from "./styles.module.css";
import { IAutomotivaKmVeiculoLocal } from "../../../../interface/AutomotivaKmVeiculoLocal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";

export const KmVeiculoLocalPage = (data: IAutomotivaKmVeiculoLocal) => {
  const { t } = useTranslation();
  const getValue = (value?: string): string => value || "Não informado";
  const result = data?.dadosKmVeiculo;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Dados KM Veículo Local</p>
      </div>

      {result?.map((item) => (
        <React.Fragment key={item?.placa}>
          <br />
          <Panel header={`KM do Veículo ${item?.placa}`} toggleable>
            <div className={styles.listaWrapper}>
              <TextField label="Marca" value={getValue(item?.marca)} />
              <TextField label="Modelo" value={getValue(item?.modelo)} />
             
            </div>
            <br />
            <br />
            <DataTable size="small" value={item.kms} tableStyle={{ minWidth: '50rem' }}>
                <Column field="dataKm" header={t('Data')}></Column>
                <Column field="quilometragem" header={t('KM')}></Column>
            </DataTable>
            <br />
            <br />
            {item?.imagens && (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                {item?.imagens.map((img, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: "250px",
                      height: "100px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      src={img}
                      alt="Image"
                      zoomSrc={img}
                      preview
                      imageStyle={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </Panel>
          <br />
        </React.Fragment>
      ))}

      {data?.MENSAGEM && <p>{data?.MENSAGEM}</p>}
    </div>
  );
};
