import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NadaConsta from "../../../components/nadaConsta";
 
 
 
export default function NegativacaoCompany({
  negativacaoCompany
}: {
  negativacaoCompany: any
}) {
  const { t } = useTranslation();
  
 
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {negativacaoCompany.refin_pefin && negativacaoCompany.refin_pefin.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span>) }</div>;
  }
 
  
  return (
    <Panel headerTemplate={headerTemplate}>
       {!negativacaoCompany.refin_pefin || negativacaoCompany.refin_pefin.length === 0  ? ( <NadaConsta />) : (
      <DataTable size="small" value={negativacaoCompany.refin_pefin} tableStyle={{ minWidth: '50rem' }}>
        <Column field="data" header={t('data')}></Column>
        <Column field="contrato" header={t('contrato')}></Column>
        <Column field="tipo" header={t('tipo')}></Column>
        <Column field="empresa" header={t('empresa')}></Column>
        <Column field="cidade" header={t('cidade')}></Column>
      </DataTable>)}
    </Panel>
  )
}