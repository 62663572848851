import { FormEvent, useState } from "react";
import { FilterWeb } from "../../interface";
import { useNavigate } from "react-router";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";

interface ListItems {
  name: string;
  permission: string;
}

interface QueryModelProps {
  fetchConsultWebService: (data: FilterWeb) => Promise<string>;
}

const initialState: ListItems = { name: "", permission: "" };

export const useQueryModel = ({ fetchConsultWebService }: QueryModelProps) => {
  const navigate = useNavigate();
  const [plate, setPlate] = useState<string>("");
  const {state} = useGlobalState();

  const [selectedItem, setSelectedItem] = useState<ListItems>(initialState);

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    const data: FilterWeb = {
      placa: plate,
      indicadores: [],
      consultas: [selectedItem.permission],
      documento: "",
      uf: selectedItem.permission.split('_')[selectedItem.permission.split('_').length - 1]
    };

    const response: any = await fetchConsultWebService(data);

    navigate("/respostacrlv", { state: { response } });
  };

  const listItems: ListItems[] = [];

  state.user.consultas.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'AUTO').sort( (a:any,b:any) => {
    {
      if(!a.ordenacao){
        return 1;
      }

      if(!b.ordenacao){
        return -1;
      }

      if (a.ordenacao > b.ordenacao) {
        return 1;
      }
      if (a.ordenacao < b.ordenacao) {
        return -1;
      }
      return 0;
    }
  } ).map((_item: any) => { _item.permissoes.filter((f: { tipoConsulta: string; }) => f.tipoConsulta === 'AUTOMOTIVA_CRLV').sort( (a:any,b:any) => {
    {
      if(!a.ordenacao){
        return 1;
      }

      if(!b.ordenacao){
        return -1;
      }

      if (a.ordenacao > b.ordenacao) {
        return 1;
      }
      if (a.ordenacao < b.ordenacao) {
        return -1;
      }
      return 0;
    }
  } ).map((_it: any) => listItems.push({name: _it.label, permission: _it.value}) )});

  return {
    listItems,
    plate,
    setPlate,
    selectedItem,
    setSelectedItem,
    onSubmit,
  };
};
