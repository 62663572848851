import { Panel } from "primereact/panel";
import Container from "../../../components/menu/Container";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FileUpload } from "primereact/fileupload";
import { useState } from "react";
import { CSSObject } from "styled-components";
import {
  ICargaLeilao,
  postCargaLeilao,
} from "../../../service/postCargaLeilao";

export const HomePage = () => {
  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const [base64Data, setBase64Data] = useState<string>("");
  const [response, setResponse] = useState<ICargaLeilao>();

  const onSelect = async (event: { files: File[] }) => {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result) {
          const base64String = btoa(
            new Uint8Array(e.target.result as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setBase64Data(base64String);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onUpload = async (event: { files: File[] }) => {
    const file = event.files[0];
    const result = await postCargaLeilao({
      nomeArquivo: file.name,
      arquivo: base64Data,
    });

    setResponse(result);
  };

  return (
    <Container>
      <h1 style={styles.title}>
        <i className="pi pi-search-plus" style={styles.icon} />
        Carga Leilão
      </h1>

      <div className="card">
        <FileUpload
          name="demo[]"
          accept=".xlsx, .xls"
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          maxFileSize={1000000}
          customUpload
          uploadHandler={onUpload}
          onSelect={onSelect}
          emptyTemplate={
            <p className="m-0">
              Arraste e solte arquivos aqui para fazer o upload.
            </p>
          }
        />
      </div>

      <br />
      <br />

      <Panel header="Erros" toggleable>
        <DataTable
          value={response?.erros}
          emptyMessage="Nenhum erro encontrado"
          paginator
          rows={10}
        >
          <Column header="Linha" field="linha" />
          <Column header="Campo" field="campo" />
          <Column header="Mensagem" field="mensagem" />
        </DataTable>
      </Panel>
    </Container>
  );
};

const styles: Record<string, CSSObject> = {
  icon: {
    fontSize: "2rem",
  },
  title: {
    marginTop: "76px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
};
