import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { Image } from 'primereact/image';
import { Calendar } from "primereact/calendar";
import { useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Carousel } from "primereact/carousel";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Panel } from "primereact/panel";
import { Column } from "primereact/column";
export default function VeiculoCadastrar({veiculo, onClose, onSave, msgs}:{veiculo:any, onClose: () => void, onSave: (veiculo:any) => void, msgs:any}) {
  const { t } = useTranslation();
  const [totalSize, setTotalSize] = useState(0);
  const [files, setFiles] = useState<any>(veiculo.id && veiculo.imagens ? veiculo.imagens : []);
  const fileUploadRef = useRef<FileUpload>(null);
  const [showKm, setShowKm] = useState<boolean>(false);
  const [kms, setKms] = useState<any[]>(veiculo.kms);
  const [km, setKm] = useState<string|null|undefined>(null);
  const [data, setData] = useState<Date|null|undefined>(null);
  const [kmEdit, setKmEdit] = useState<any>();
  
  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    let _totalSize = totalSize;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
        _totalSize += files[i].size || 0;
    }

    setTotalSize(_totalSize);
   };

   const uploadHandler = async (e: FileUploadHandlerEvent) => {
        if(e.files.length + files.length > 4){
            msgs.current?.clear();
            msgs.current?.show([
                { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Limite de imagens atingido', closable: false }
               
            ]);
        }
        else{
            e.files.forEach(async (file:any) => {
                const reader = new FileReader();
                let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    
                reader.readAsDataURL(blob);
    
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setFiles((files: any) => [...files,base64data])
                };
            });    
            if(fileUploadRef && fileUploadRef.current){
                fileUploadRef.current.clear()
            }
        }
        
   }

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize); 
    };

    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex-score align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as any;
        return (
            <div className="flex-score align-items-center flex-wrap">
                <div className="flex-score align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex-score flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex-score align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    arrate e solte sua imagem aqui
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
  
  const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
    return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };
  const defaultValues = {
    placa: '',
    modelo: '',
    marca: '',
    quilometragem: ''
  };
  defaultValues.placa = veiculo.placa ? veiculo.placa.toString() : ''
  defaultValues.modelo = veiculo.modelo ? veiculo.modelo.toString() : ''
  defaultValues.marca = veiculo.marca ? veiculo.marca.toString() : ''
  
  const {
      control,
      formState: { errors},
      handleSubmit,
      setValue
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.placa && save(data);
  };

 
  const save = (data:any) => {
    const vei = {
      id: veiculo.id? veiculo.id : undefined,
      placa: data.placa.replace('-', ''),
      marca: data.marca,
      modelo: data.modelo,
      imagens: files,
      kms: kms
    }
    window.scrollTo(0, 0);
    onSave(vei);
  }

  const footerTable = (
    <React.Fragment>
      {!showKm && (
          <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Adicionar Km" onClick={()=>setShowKm(true)} />
          </div>
      )}
      {showKm && (
            <Panel>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <label htmlFor="dataFinal" className="font-bold block mb-2 required">{t('data')} </label>
                  <Calendar id='dataFinal' value={data} onChange={(e) => setData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
                <div className="col p-fluid flex flex-wrap">
                    <label htmlFor="servico" className="font-bold block mb-2 required">{t('km')} </label>
                    <InputText value={km} onChange={(e) => setKm(e.target.value)} />
                  </div>
              </div>
               <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar Km" onClick={() => salvarKm()}  />
                <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setShowKm(false)} className="p-button-text" />
              </div>
            </Panel>
      )}
    </React.Fragment>
  );

  const editKmBodyTemplate = (item:any) => {
    return <>
            <Button icon="pi pi-pencil" type="button" onClick={() => {setData(item.data); setKm(item.quilometragem);setKmEdit(item);setShowKm(true)}} style={{background:'transparent', color:'gray', border: 'none'}} />
            <Button icon="pi pi-trash" type="button" onClick={() => {setKms(kms?.filter((v) => formatDate(v.data) !== formatDate(item.data)))}} style={{background:'transparent', color:'gray', border: 'none'}} />
          </>
  };

  const fileTemplate = (file: any) => {
    return (
        <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
            <div className="mb-3">
            <Image src={file as string} alt="imagem" width="100rem" height="100rem" preview/>
            </div>
            <div>
                <div className="mt-5 flex-score flex-wrap gap-2 justify-content-center">
                    <Button className="button_relatorio" style={{color:'red'}} icon="pi pi-trash" rounded outlined severity="danger" onClick={() => setFiles(files.filter((f:string) => f !== file))}/>
                </div>
            </div>
        </div>
    );
 };

  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const dateBodyTemplate = (rowData: { data: any; }) => {
    return formatDate(rowData.data);
  };

  const salvarKm = () => {
    if(data && km){
      const lista = []
      kms?.forEach((p) => lista.push(p))
      const results = kms?.filter((p) => formatDate(p.data) === formatDate(data))
      if(results && results.length !== 0 && (!kmEdit || formatDate(kmEdit.data) !== formatDate(data))){
        msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Data já existe', closable: false }
           
        ]);
        window.scrollTo(0, 0);
      }
      else{
        if(!kmEdit){
          lista.push({data:data, quilometragem:km})
        }
        else{
          kmEdit.quilometragem = km;
          kmEdit.data = data;
          
        }
        setKmEdit(null)
        setData(null)
        setKm(null)
        setKms(lista)
        setShowKm(false)
      }
      
    }
    else{
      if (msgs.current) {
        msgs.current?.clear();
        msgs.current?.show([
            { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Data e valor são obrigatórios', closable: false }
           
        ]);
        window.scrollTo(0, 0);
      }
    }
    
   }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="placa"
                      control={control}
                      rules={{ required: 'Placa é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.placa })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="placa" className="font-bold block mb-2 required">{t('placa')} </label>
                                <InputMask disabled={veiculo.id} id={field.name}  value={field.value} className={classNames({ 'p-invalid': fieldState.error })} mask="aaa-9*99" onChange={(e) => field.onChange(e.target.value)}></InputMask>
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
               
               
              </div>
          </div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="marca"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.marca })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="marca" className="font-bold block mb-2">{t('marca')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                <Controller
                      name="modelo"
                      control={control}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.modelo })}></label>
                              <span className="">
                              <div>
                                  <label htmlFor="modelo" className="font-bold block mb-2">{t('modelo')} </label>
                                  <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                              </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
               
              </div>
              
            </div>
            <div className="grid">
                <div className="col">
                    <DataTable size="small" footer={footerTable} value={kms} tableStyle={{ minWidth: '50rem' }}>
                        <Column  body={dateBodyTemplate} dataType="Date" header={t('data')}></Column>
                        <Column field="quilometragem" header={t('km')}></Column>
                        <Column body={editKmBodyTemplate} />
                    </DataTable>
                    <br></br>
                </div>
            </div>
            
            
            <div className="grid">
                <div style={{minWidth: '800px'}}>
                    <FileUpload ref={fileUploadRef} name="demo[]" customUpload uploadHandler={uploadHandler} multiple accept="image/*" maxFileSize={1000000}
                        onUpload={onTemplateUpload}  onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="card">
                <Carousel value={files} numVisible={3} numScroll={3} itemTemplate={fileTemplate} />
            </div>
          
          
          
        </div>
        <br></br>
        <br></br>
      
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}


