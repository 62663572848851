import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Card } from "../../../../components/Card";
import { Fragment } from "react/jsx-runtime";

export const Gps894Page = (data: any) => {
  const PENDENCIAS_F = data?.PEND_FINANCEIRAS;
  const PASSAGENS_C = data?.PASSAGENS_COMERCIAIS;
  const RENDA_P = data?.RENDA_PRESUMIDA;
  const DADOS_P = data?.IDENTIFICACAO_PESSOA_FISICA;

  const getValue = (value: string) => value || "Não informado";

  const footer = () => {
    return <p>Valor Total: {PENDENCIAS_F?.VALOR_TOTAL}</p>;
  };

  const footerProtesto = () => (
    <p>Valor Total: {data?.PROTESTOS?.VALOR_TOTAL}</p>
  );

  return (
    <Panel header="Consulta GPS 894" toggleable style={{ width: "98%" }}>
      <Panel header="Scores" toggleable>
        <Card.Container>
          {data?.SCORES?.OCORRENCIAS?.map((item: any, index: number) => (
            <Fragment key={index}>
              <Card.Item label="Score" value={item?.SCORE} />
              <Card.Item label="Plano" value={item?.DESCR_PLANO} />
              <Card.Item label="Risco" value={item?.RISCO} />
              <Card.Item label="Tipo de Score" value={item?.TIPO_SCORE} />
              <Card.Item label="Classificação ABC" value={item?.CLASSIF_ABC} />
              <Card.Item label="Descrição do Score" value={item?.DESCR_SCORE} />
              <Card.Item label="Observação" value={item?.TEXTO} />
              <Card.Item
                label="Probabilidade de Inadimplência"
                value={item?.PROBABILIDADE_INADIMPLENCIA}
              />
              <br />
              <br />
            </Fragment>
          ))}
        </Card.Container>
      </Panel>

      <br />
      <br />

      <Panel header="Informações Pessoais" toggleable>
        <Card.Container>
          <Card.Item label="Nome" value={DADOS_P?.NOME} />
          <Card.Item label="Nome Social" value={DADOS_P?.NOME_SOCIAL} />
          <Card.Item label="Sexo" value={DADOS_P?.SEXO} />
          <Card.Item label="Data de Nascimento" value={DADOS_P?.NASCIMENTO} />
          <Card.Item label="Nacionalidade" value={DADOS_P?.NACIONALIDADE} />
          <Card.Item label="Naturalidade" value={DADOS_P?.NATURALIDADE} />
        </Card.Container>
      </Panel>

      <br />
      <br />

      <Panel header="Documentação" toggleable>
        <Card.Container>
          <Card.Item label="CPF" value={DADOS_P?.CPF_NUMERO} />
          <Card.Item label="Situação do CPF" value={DADOS_P?.CPF_SITUACAO} />
          <Card.Item label="Origem do CPF" value={DADOS_P?.CPF_ORIGEM} />
          <Card.Item
            label="Última Atualização do CPF"
            value={DADOS_P?.CPF_DATA_ATUALIZACAO_RECEITA}
          />

          <Card.Item label="RG" value={DADOS_P?.RG_NUMERO} />
          <Card.Item
            label="Data de Emissão do RG"
            value={DADOS_P?.RG_DATA_EMISSAO}
          />
          <Card.Item label="UF do RG" value={DADOS_P?.RG_UF} />

          <Card.Item
            label="Título de Eleitor"
            value={DADOS_P?.TITULO_ELEITOR}
          />
          <Card.Item label="Número do PIS" value={DADOS_P?.NUMERO_PIS} />

          <Card.Item label="Nome da Mãe" value={DADOS_P?.MAE} />
          <Card.Item label="Nome do Pai" value={DADOS_P?.NOME_PAI} />
          <Card.Item
            label="Indicação de Óbito"
            value={DADOS_P?.INDICACAO_OBITO}
          />
        </Card.Container>
      </Panel>
      <br />
      <br />
      <Panel header="Outras Informações" toggleable>
        <Card.Container>
          <Card.Item
            label="Grau de Instrução"
            value={DADOS_P?.GRAU_INSTRUCAO}
          />
          <Card.Item
            label="Número de Dependentes"
            value={DADOS_P?.NUMERO_DEPENDENTES}
          />
          <Card.Item label="Estado Civil" value={DADOS_P?.ESTADO_CIVIL} />
        </Card.Container>
      </Panel>
      <br />
      <br />
      <Panel header="Endereço" toggleable>
        <Card.Container>
          <Card.Item
            label="Endereço"
            value={`${DADOS_P?.END_LOGRADOURO}, ${DADOS_P?.END_BAIRRO}`}
          />
          <Card.Item label="Cidade" value={DADOS_P?.END_CIDADE} />
          <Card.Item label="UF" value={DADOS_P?.END_UF} />
          <Card.Item label="CEP" value={DADOS_P?.END_CEP} />
        </Card.Container>
      </Panel>
      <br />
      <br />
      <Panel header="Contato" toggleable>
        <Card.Container>
          <Card.Item label="Telefone" value={DADOS_P?.TELEFONE} />
          <Card.Item label="E-mail" value={DADOS_P?.EMAIL} />
        </Card.Container>
      </Panel>
      <br />
      <br />
      <Panel header="Renda Presumida" toggleable>
        <Card.Container>
          <Card.Item
            label="Data de Referência"
            value={getValue(RENDA_P?.DATA_REFERENCIA)}
          />
          <Card.Item
            label="Valor Presumido"
            value={getValue(RENDA_P?.VALOR_PRESUMIDO)}
          />
          <Card.Item
            label="Renda Anual"
            value={getValue(RENDA_P?.RENDA_ANUAL)}
          />
          <Card.Item
            label="Dados Cadastro Positivo"
            value={getValue(RENDA_P?.DADOS_CADASTRO_POSITIVO)}
          />
          <Card.Item label="Faixa" value={getValue(RENDA_P?.FAIXA)} />
        </Card.Container>
      </Panel>
      <br />
      <br />
      <Panel header="Protestos" toggleable>
        <DataTable
          value={data?.PROTESTOS?.OCORRENCIAS}
          emptyMessage="Nada consta"
          footer={footerProtesto}
        >
          <Column
            header="Credor"
            body={(data: any) => getValue(data?.CREDOR)}
          />
          <Column
            header="Origem"
            body={(data: any) => getValue(data?.ORIGEM)}
          />
          <Column
            header="Quantidade"
            body={(data: any) => getValue(data?.QUANTIDADE)}
          />
          <Column header="Data" body={(data: any) => getValue(data?.DATA)} />
          <Column
            header="Vencimento"
            body={(data: any) => getValue(data?.VENCIMENTO)}
          />
        </DataTable>
      </Panel>

      <br />
      <br />

      <Panel header="Pendências Financeiras" toggleable>
        <DataTable
          value={PENDENCIAS_F?.OCORRENCIAS}
          emptyMessage="Nada consta"
          footer={footer}
        >
          <Column header="Modalidade" field="MODALIDADE" />
          <Column header="Contrato" field="CONTRATO" />
          <Column header="Tipo de Devedor" field="TIPO_DEVEDOR" />
          <Column header="Credor" field="CREDOR" />
          <Column header="Valor" field="VALOR" />
          <Column header="Data de Vencimento" field="DATA_VENCIMENTO" />
          <Column header="Data de Inclusão" field="DATA_INCLUSAO" />
          <Column header="Origem" field="ORIGEM" />
        </DataTable>
      </Panel>
      <br />
      <br />
      <Panel header="Passagens Comerciais" toggleable>
        <DataTable value={PASSAGENS_C?.OCORRENCIAS} emptyMessage="Nada consta">
          <Column header="Cliente Consulta" field="CLIENTE_CONSULTA" />
          <Column header="Cidade/UF Cliente" field="CIDADE_UF_CLIENTE" />
          <Column header="Data da Consulta" field="DATA_CONSULTA" />
          <Column header="Hora da Consulta" field="HORA_CONSULTA" />
          <Column header="Telefone do Cliente" field="TELEFONE_CLIENTE" />
        </DataTable>
      </Panel>
    </Panel>
  );
};
