import { useTranslation } from "react-i18next";
import { Panel } from "primereact/panel";
import { Chip } from "primereact/chip";
import OutPutCard from "../../../components/outputCard";
import { Divider } from "primereact/divider";

export default function AutomotivaRenavam({
  automotivaRenavam,
}: {
  automotivaRenavam: any;
}) {
  const { t } = useTranslation();
  automotivaRenavam = automotivaRenavam.RETORNO;
  return (
    <Panel header={t("Automotiva Renavam")} style={{ width: "98%" }}>
      {!automotivaRenavam?.MSG_ERRO && (
        <>
          <Divider align="left">
            <div className="flex-score justify-content-between justify-content-center">
              <span
                className="p-tag"
                style={{
                  position: "relative",
                  backgroundColor: "#183462",
                  fontSize: "1.1rem",
                  height: "2rem",
                  marginTop: "0.5rem",
                }}
              >
                Resumo do Veículo
              </span>
            </div>
          </Divider>
          <br></br>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("placa")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.PLACA}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("marcaModelo")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.MARCA_MODELO}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("chassi")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.CHASSI}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("motor")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.MOTOR}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("renavam")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.RENAVAM}
                />
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <label className="label-dados">{t("cor")}</label>
                <Chip style={{ width: "100%" }} label={automotivaRenavam.COR} />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("combustivel")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={automotivaRenavam.COMBUSTIVEL}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label className="label-dados">{t("anoFabricaoModelo")}</label>
                <Chip
                  style={{ width: "100%" }}
                  label={
                    automotivaRenavam.ANO_FABRICACAO +
                    "/" +
                    automotivaRenavam.ANO_MODELO
                  }
                />
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <Divider align="left">
            <span
              className="p-tag"
              style={{ backgroundColor: "#183462", fontSize: "1.1rem" }}
            >
              Dados Renavam
            </span>
          </Divider>
          <br></br>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Capacidade Carga"
                  value={automotivaRenavam.CAPACIDADE_CARGA}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Tipo"
                  value={automotivaRenavam.TIPO}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="UF"
                  value={automotivaRenavam.UF}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Município"
                  value={automotivaRenavam.MUNICIPIO}
                ></OutPutCard>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Espécie"
                  value={automotivaRenavam.ESPECIE}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Procedência"
                  value={automotivaRenavam.PROCEDENCIA}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Carroceria"
                  value={automotivaRenavam.CARROCERIA}
                ></OutPutCard>
              </div>
            </div>
            <div className="col p-fluid flex flex-wrap">
              <div>
                <OutPutCard
                  label="Cilindradas"
                  value={automotivaRenavam.CILINDRADAS}
                ></OutPutCard>
              </div>
            </div>
          </div>
        </>
      )}

      {automotivaRenavam?.MSG_ERRO && (
        <p>
          ERRO NA CONSULTA. TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM
          CONTATO COM O SUPORTE.
        </p>
      )}
    </Panel>
  );
}
