import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IVeiculoProprietario } from "../../../../interface/VeiculoProprietario";

export const VeiculoProprietarioPage = (
  data: IVeiculoProprietario
) => {
  return (
    <Panel header="Automotiva Proprietário Veicular" toggleable>
      <DataTable value={data?.dados.registro} emptyMessage="Nada consta">
        <Column header="Placa" field="Placa" />
        <Column header="Renavam" field="Renavam" />
        <Column header="Chassi" field="Chassi" />
        <Column header="Marca" field="MarcaModelo" />
        <Column header="Cor" field="Cor" />
        <Column header="Tipo" field="TipoVeiculo" />
        <Column header="Capacidade" field="Cap_Passageiros" />
        <Column header="Ano Modelo" field="AnoModelo" />
        <Column header="Ano Fabricação" field="AnoFabricacao" />
        <Column header="Motor" field="Motor" />
        <Column header="Município" field="Municipio" />
        <Column header="UF" field="Uf" />
        <Column header="Combustível" field="Combustivel" />
        <Column header="Potência" field="Potencia" />
        <Column header="Espécie" field="Especie" />
      </DataTable>
    </Panel>
  );
};
