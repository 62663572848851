import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { IConsultaRenajud } from "../../../../interface/ConsultaRenajud";
import React from "react";

export const ConsultaRenajudPage = (data: IConsultaRenajud[]) => {
  
  const renderRestricao = (data: any) => {
    return (
      <p>
        {data?.restricoes?.map((item: any, index: number) => (
          <React.Fragment key={item?.descricao}>
            {item?.descricao}
            {index < data?.restricoes?.length - 1 && " / "}
          </React.Fragment>
        ))}
      </p>
    );
  };

  return (
    <Panel header="Consulta Renajud" toggleable>
      <DataTable value={data[0]} emptyMessage="Nada consta">
        <Column header="Tribunal" field="tribunal" />
        <Column header="Ramo" field="ramo" />
        <Column header="Orgão Judiciário" field="orgaoJudiciario" />
        <Column header="Nº Processo" field="processo" />
        <Column header="Restrições" body={renderRestricao} />
      </DataTable>
    </Panel>
  );
};
