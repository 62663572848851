import { Panel } from "primereact/panel";
import { Card } from "../../../../components/Card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const ConsultaNacionalVeicularPage = (data: any) => {
  const getValue = (value: any) => (value ? value : "Não informado");

  return (
    <Panel
      header="Consulta Nacional Veícular"
      toggleable
      style={{ width: "98%" }}
    >
      {data && (
        <>
          <Panel header="Proprietário" toggleable>
            {data?.proprietario && (
              <Card.Container>
                <Card.Item
                  label="Nome"
                  value={getValue(data?.proprietario?.nomeProprietario)}
                />
                <Card.Item
                  label="CPF/CNPJ"
                  value={getValue(
                    data?.proprietario?.numeroDocumentoProprietario?.maskCpfCnpj()
                  )}
                />
                <Card.Item
                  label="Endereço"
                  value={getValue(data?.proprietario?.enderecoProprietario)}
                />
              </Card.Container>
            )}

            {!data?.proprietario && <p>Nada consta</p>}
          </Panel>

          <br />
          <br />

          <Panel header="Possuidor" toggleable>
            {data?.possuidor && (
              <Card.Container>
                <Card.Item
                  label="Nome"
                  value={getValue(data?.possuidor?.nomePossuidor)}
                />
                <Card.Item
                  label="CPF/CNPJ"
                  value={getValue(
                    data?.possuidor?.numeroDocumentoPossuidor?.maskCpfCnpj()
                  )}
                />
                <Card.Item
                  label="Endereço"
                  value={getValue(data?.possuidor?.enderecoPossuidor)}
                />
              </Card.Container>
            )}

            {!data?.possuidor && <p>Nada consta</p>}
          </Panel>

          <br />
          <br />

          <Panel header="Arrendatário" toggleable>
            {data?.arrendatario && (
              <Card.Container>
                <Card.Item
                  label="Nome"
                  value={getValue(data?.arrendatario?.nomeArrendatario)}
                />
                <Card.Item
                  label="CPF/CNPJ"
                  value={getValue(
                    data?.arrendatario?.numeroDocumentoArrendatario?.maskCpfCnpj()
                  )}
                />
                <Card.Item
                  label="Endereço"
                  value={getValue(data?.arrendatario?.enderecoArrendatario)}
                />
              </Card.Container>
            )}

            {!data?.arrendatario && <p>Nada consta</p>}
          </Panel>

          <br />
          <br />

          <Panel header="Identificação do Veículo" toggleable>
            <Card.Container>
              <Card.Item label="Placa" value={getValue(data?.placa)} />
              <Card.Item
                label="Placa Pré-Mercosul"
                value={getValue(data?.placaPreMercosul)}
              />
              <Card.Item label="Chassi" value={getValue(data?.chassi)} />
              <Card.Item label="Renavam" value={getValue(data?.renavam)} />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Emplacamento e Jurisdição" toggleable>
            <Card.Container>
              <Card.Item
                label="UF de Emplacamento"
                value={getValue(data?.ufEmplacamento)}
              />
              <Card.Item
                label="Município de Emplacamento"
                value={getValue(data?.municipioPlaca)}
              />
              <Card.Item
                label="Código Município"
                value={getValue(data?.codigoMunicipioEmplacamento)}
              />
              <Card.Item
                label="UF de Jurisdição"
                value={getValue(data?.ufJurisdicaoVeiculo)}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Características do Veículo" toggleable>
            <Card.Container>
              <Card.Item
                label="Marca/Modelo"
                value={getValue(data?.marcaModelo)}
              />
              <Card.Item
                label="Ano de Fabricação"
                value={getValue(data?.anoFabricacao)}
              />
              <Card.Item
                label="Ano do Modelo"
                value={getValue(data?.anoModelo)}
              />
              <Card.Item label="Cor" value={getValue(data?.cor)} />
              <Card.Item
                label="Combustível"
                value={getValue(data?.combustivel)}
              />
              <Card.Item
                label="Tipo de Veículo"
                value={getValue(data?.tipoVeiculo)}
              />
              <Card.Item label="Categoria" value={getValue(data?.categoria)} />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Motorização" toggleable>
            <Card.Container>
              <Card.Item
                label="Número do Motor"
                value={getValue(data?.numeroMotor)}
              />
              <Card.Item label="Potência" value={getValue(data?.potencia)} />
              <Card.Item
                label="Cilindrada"
                value={getValue(data?.cilindrada)}
              />
              <Card.Item label="Lotação" value={getValue(data?.lotacao)} />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Histórico e Restrições" toggleable>
            <Card.Container>
              <Card.Item
                label="Data de Emplacamento"
                value={getValue(data?.dataEmplacamento)}
              />
              <Card.Item
                label="Data de Atualização"
                value={getValue(data?.dataAtualizacaoVeiculo)}
              />
              <Card.Item
                label="Data de Atualização Roubo/Furto"
                value={getValue(data?.dataAtualizacaoRouboFurto)}
              />
              <Card.Item
                label="Data de Atualização Alarme"
                value={getValue(data?.dataAtualizacaoAlarme)}
              />
              <Card.Item
                label="Restrições"
                value={getValue(data?.restricaoVeiculo1)}
              />
              <Card.Item
                label="Situação do Veículo"
                value={getValue(data?.situacaoVeiculo)}
              />
            </Card.Container>
          </Panel>

          <br />
          <br />

          <Panel header="Indice Nacional de Veículos" toggleable>
            <DataTable
              value={data?.indiceNacionalVeiculos}
              emptyMessage="Nada consta"
            >
              <Column header="Método" field="metodo" />
              <Column header="Qtd" field="qtd" />
            </DataTable>
          </Panel>
        </>
      )}
    </Panel>
  );
};
