import { useTranslation } from "react-i18next";
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NadaConsta from "../../../components/nadaConsta";
 
 
 
export default function Analitica({
  analitica
}: {
  analitica: any
}) {
  const { t } = useTranslation();
  
 
 
 
 
 
 
  
 
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {analitica.scpc && analitica.scpc.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('restricoesMercado')}</span>) }</div>;
  }
 
  const headerTemplateNegativacao = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {analitica.refin_pefin && analitica.refin_pefin.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('negativacoes')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('negativacoes')}</span>) }</div>;
  }
 
  const headerTemplateProtesto = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {analitica.protestos && analitica.protestos.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('protestos')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('protestos')}</span>) }</div>;
  }
 
 
  const headerTemplateCCF = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} `;
    return  <div className={className}> {analitica.cheques && analitica.cheques.length > 0 ? (<span style={{color:'#C70039', fontSize: '1.5rem'}} className="pi pi-exclamation-triangle"><span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('ccf')}</span></span>) : (<span className="p-panel-title" style={{marginLeft:'1rem', fontFamily: 'var(--font-family)', fontSize: '1rem', color:'black'}}>{t('ccf')}</span>) }</div>;
  }
 
  const cnpjEmpresa = (item:any) => {
    return item.cnpj.maskCpfCnpj();
  };
  
  return (
    <>
      {!analitica.msg &&(
        <Panel header={t('consumidor')}>
        <div className="grid">
            <div className="col p-fluid flex flex-wrap">
              <div >
                  <label className="label-dados">{t('documento')}</label>
                  <Chip style={{width:"100%"}} label={analitica.doc.maskCpfCnpj()} />
              </div>
            </div>
            <div className="col">
            <div >
                  <label className="label-dados">{t('nome')}</label>
                  <Chip style={{width:"100%"}} label={analitica.nome} />
              </div>
            </div>
        </div>
        {analitica.brasil && (<>          
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('nomeFantasia')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.nome_fantasia} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('capitalSocial')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.capital_social} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('naturezaJuridica')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.natureza_juridica.id + ' - ' + analitica.brasil.natureza_juridica.descricao} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('atividadePrincipal')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.atividade_principal.descricao} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('tipo')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.tipo} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('situacao')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.situacao_cadastral} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('email')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.email} />
                </div>
              </div>
              <div className="col">
              <div >
                    <label className="label-dados">{t('telefone')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.ddd1 + ' ' + analitica.brasil.estabelecimento.telefone1} />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <label className="label-dados">{t('endereco')}</label>
                    <Chip style={{width:"100%"}} label={analitica.brasil.estabelecimento.logradouro + ', ' + analitica.brasil.estabelecimento.numero + ' ' + analitica.brasil.estabelecimento.complemento + ' ' + analitica.brasil.estabelecimento.bairro + ' ' + analitica.brasil.estabelecimento.cidade.nome + '/' + analitica.brasil.estabelecimento.estado.sigla + ' - ' + analitica.brasil.estabelecimento.cep} />
                </div>
              </div>
              
            </div>
            
          </>
 
        )}
       
      </Panel>
      ) }
      
      <br></br>
      {analitica.brasil &&(<>
        <Panel header={t('socios')}>
          <DataTable size="small" value={analitica.brasil.socios} tableStyle={{ minWidth: '50rem' }}>
              <Column field="cpf_cnpj_socio" header={t('documento')}></Column>
              <Column field="data_entrada" header={t('data')}></Column>
              <Column field="tipo" header={t('tipo')}></Column>
              <Column field="qualificacao_socio.descricao" header={t('classificacao')}></Column>
              <Column field="nome" header={t('nome')}></Column>
              <Column field="faixa_etaria" header={t('faixaEtaria')}></Column>
          </DataTable>
          
        </Panel>
        <br></br>
        </>
      )}
      
      <Panel header={t('historicoEndereco')}>
       
       <DataTable size="small" value={analitica.enderecos} tableStyle={{ minWidth: '50rem' }}>
          <Column field="ENDERECO" header={t('logradouro')}></Column>
          <Column field="CEP" header={t('cep')}></Column>
          <Column field="BAIRRO" header={t('bairro')}></Column>
          <Column field="CIDADE" header={t('cidade')}></Column>
          <Column field="UF" header={t('uf')}></Column>
          <Column field="LOGR_COMPLEMENTO" header={t('complemento')}></Column>
          <Column field="LOGR_NUMERO" header={t('numero')}></Column>
          <Column field="DT_INCLUSAO" header={t('data')}></Column>
      </DataTable>
    </Panel>
    <br></br>
    <Panel header={t('telefones')}>
       
       <DataTable size="small" value={analitica.telefones} tableStyle={{ minWidth: '50rem' }}>
          <Column field="TIPO_DS" header={t('tipo')}></Column>
          <Column field="DDD" header={t('ddd')}></Column>
          <Column field="TELEFONE" header={t('fone')}></Column>
          <Column field="DT_INCLUSAO" header={t('data')}></Column>
      </DataTable>
    </Panel>
    <br></br>
    <Panel header={t('empresas')}>
      <DataTable size="small" value={analitica.empresas} tableStyle={{ minWidth: '50rem' }}>
        <Column body={cnpjEmpresa} header={t('cnpj')}></Column>
        <Column field="razaosocial" header={t('razaoSocial')}></Column>
        <Column field="fantasia" header={t('nomeFantasia')}></Column>
        <Column field="cidade" header={t('cidade')}></Column>
        <Column field="uf" header={t('uf')}></Column>
        <Column field="porcentagem" header={t('percentual')}></Column>
        <Column field="cnae" header={t('cnae')}></Column>
        <Column field="cnaesecun" header={t('cnaeSecundario')}></Column>
      </DataTable>
    
    </Panel>
    <br></br>
    <Panel headerTemplate={headerTemplate}>
       {!analitica.scpc || analitica.scpc.length === 0 ? ( <NadaConsta />) : (<>
       <DataTable size="small" value={analitica.scpc} tableStyle={{ minWidth: '50rem' }}>
          <Column field="data_disponivel" header={t('dataRegistro')}></Column>
          <Column field="data" header={t('dataVencimento')}></Column>
          <Column field="contrato" header={t('contrato')}></Column>
          <Column field="nome" header={t('credor')}></Column>
          <Column field="cidade" header={t('cidadeUF')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
    <br></br>
    <Panel headerTemplate={headerTemplateNegativacao}>
       {!analitica.refin_pefin || analitica.refin_pefin.length === 0 ? ( <NadaConsta />) : (<>
       <DataTable size="small" value={analitica.refin_pefin} tableStyle={{ minWidth: '50rem' }}>
          <Column field="data" header={t('data')}></Column>
          <Column field="contrato" header={t('contrato')}></Column>
          <Column field="empresa" header={t('credor')}></Column>
          <Column field="tipo" header={t('natureza')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
    <br></br>
    {analitica.protestos && (
      <div>
         <Panel headerTemplate={headerTemplateProtesto}>
          {analitica.protestos.length === 0 ? ( <NadaConsta />) : (<>
          <DataTable size="small" value={analitica.protestos} tableStyle={{ minWidth: '50rem' }}>
              <Column field="data" header={t('data')}></Column>
              <Column field="cartorio" header={t('cartorio')}></Column>
              <Column field="cidade" header={t('cidade')}></Column>
              <Column field="uf" header={t('uf')}></Column>
              <Column field="valor" header={t('valor')}></Column>
          </DataTable>
          </>)}
        </Panel>
        <br></br>
      </div>
    )}
   
    <Panel headerTemplate={headerTemplateCCF}>
       {!analitica.cheques || analitica.cheques.length === 0 ? ( <NadaConsta />) : (<>
       <DataTable size="small" value={analitica.cheques} tableStyle={{ minWidth: '50rem' }}>
          <Column field="banco" header={t('banco')}></Column>
          <Column field="agencia" header={t('agencia')}></Column>
          <Column field="alinea" header={t('alinea')}></Column>
          <Column field="data" header={t('data')}></Column>
          <Column field="valor" header={t('valor')}></Column>
      </DataTable>
      </>)}
    </Panel>
      
 
 
    </>
  )
}