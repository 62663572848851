import { http } from "../../config/axios";

export type Params = {
  nomeArquivo: string;
  arquivo: string;
};

export interface ICargaLeilao {
  mensagem: string;
  erros?: {
    linha: number;
    campo: string;
    mensagem: string;
  }[];
}

export const postCargaLeilao = async ({
  arquivo,
  nomeArquivo,
}: Params): Promise<ICargaLeilao> => {
  return http
    .post<ICargaLeilao>("api/leilao/lote", {
      nomeArquivo,
      arquivo,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("Erro ao inserir arquivo", error);
      throw Error(error.response.data);
    });
};
