import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import React from "react";
import { TextField } from "../../../../components/TextField";
import { IAutomotivaLeilaoLocal } from "../../../../interface";
import styles from "./styles.module.css";

export const AutomotivaLeilaoLocalPage = (data: IAutomotivaLeilaoLocal) => {
  const getValue = (value?: string): string => value || "Não informado";
  const result = data?.dadosLeiloes;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Dados Leilão Local</p>
      </div>

      {result?.map((item) => (
        <React.Fragment key={item?.id}>
          <br />
          <Panel header={`Leilão ${item?.dataLeilao?.formatDate() ? item?.dataLeilao?.formatDate() : ''}`} toggleable>
            <div className={styles.listaWrapper}>
              <TextField label="Marca" value={getValue(item?.marca)} />
              <TextField label="Modelo" value={getValue(item?.modelo)} />
              <TextField
                label="Ano de Fabricação"
                value={getValue(item?.anoFabricacao?.toString())}
              />
              <TextField
                label="Ano do Modelo"
                value={getValue(item?.anoModelo?.toString())}
              />
              <TextField label="Cor" value={getValue(item?.cor)} />
              <TextField
                label="Combustível"
                value={getValue(item?.combustivel)}
              />
              <TextField label="Placa" value={getValue(item?.placa)} />
              <TextField label="Chassi" value={getValue(item?.chassi)} />
              <TextField label="Condição" value={getValue(item?.condicao)} />
              <TextField label="Lote" value={getValue(item?.lote)} />
              <TextField label="Leiloeiro" value={getValue(item?.leiloeiro)} />
              <TextField
                label="Data do Leilão"
                value={getValue(item?.dataLeilao)}
              />
              <TextField label="Comitente" value={getValue(item?.comitente)} />
            </div>
            <br />
            <br />
            {item?.imagens && (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                {item?.imagens.map((img, index) => (
                  <div
                    key={index}
                    style={{
                      maxWidth: "250px",
                      height: "100px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      src={img}
                      alt="Image"
                      zoomSrc={img}
                      preview
                      imageStyle={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </Panel>
          <br />
        </React.Fragment>
      ))}

      {data?.MENSAGEM && <p>{data?.MENSAGEM}</p>}
    </div>
  );
};
