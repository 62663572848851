import { FunctionComponent } from "react";
import { RouterProvider } from "react-router";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { MakeHomeCrlvPage, MakeResultCrlvPage } from "../pages/consultaCrlv";
import Page404 from "../pages/404";
import AlterarSenha from "../pages/alterarSenha";
import Cliente from "../pages/cliente";
import Consulta from "../pages/consulta";
import Consultas from "../pages/consultas";
import Fatura from "../pages/fatura";
import Faturamento from "../pages/faturamento";
import Franquia from "../pages/franquia";
import Home from "../pages/home";
import Leilao from "../pages/leilao";
import Login from "../pages/login";
import ParametroAplicacao from "../pages/parametroAplicacao";
import Permissao from "../pages/permissao";
import Plano from "../pages/plano";
import RecuperarSenha from "../pages/recuperarSenha";
import RedefinirSenha from "../pages/redefinirSenha";
import Registro from "../pages/registro";
import RelatorioApuracaoBilhete from "../pages/relatorioApuracaoBilhete";
import RelatorioConsultaSintetica from "../pages/relatorioConsultaSintetica";
import Servico from "../pages/servico";
import Usuario from "../pages/usuario";
import Veiculo from "../pages/veiculo";
import VeiculoTransferenciaPage from "../pages/veiculoTransferencia";
import VeiculoVenda from "../pages/veiculoVenda";
import { useGlobalState } from "./globalState/GlobalStateProvider";
import { HomePage } from "../pages/cargaLeilao";

const PermissionLayout = ({ permission }: any) => {
  const { state } = useGlobalState();
  const hasPermission = !permission || state.user?.funcoes.includes(permission);

  return hasPermission ? <Outlet /> : <Navigate to="/404" replace />;
};

const router = createBrowserRouter(
  [
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/recuperacao",
          element: <RecuperarSenha />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/redefinir/:hash",
          element: <RedefinirSenha />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"FATURAMENTO_VISUALIZAR"} />,
      children: [
        {
          path: "/faturamento",
          element: <Faturamento />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"FATURA_VISUALIZAR"} />,
      children: [
        {
          path: "/fatura",
          element: <Fatura />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"SERVICO_VISUALIZAR"} />,
      children: [
        {
          path: "/servico",
          element: <Servico />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"PLANO_VISUALIZAR"} />,
      children: [
        {
          path: "/plano",
          element: <Plano />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"HOME"} />,
      children: [
        {
          path: "/home",
          element: <Home />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CONSULTAR"} />,
      children: [
        {
          path: "/consultar",
          element: <Consulta />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CONSULTAR_CREDITO"} />,
      children: [
        {
          path: "/consultarcredito",
          element: <Consulta />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CONSULTAR_VEICULAR"} />,
      children: [
        {
          path: "/consultarveicular",
          element: <Consulta />,
        },
      ],
    },

    {
      element: <PermissionLayout permission={"CONSULTAR_CADASTRAL"} />,
      children: [
        {
          path: "/consultarcadastral",
          element: <Consulta />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CONSULTAR"} />,
      children: [
        {
          path: "/consultar/:id",
          element: <Consulta />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"AUTO_CRLV_SIMPLES"} />,
      children: [
        {
          path: "/consultarcrlv",
          element: <MakeHomeCrlvPage />,
        },
        {
          path: "/respostacrlv",
          element: <MakeResultCrlvPage />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CONSULTA_VISUALIZAR"} />,
      children: [
        {
          path: "/historico",
          element: <Consultas />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"RELATORIO_CONSULTA_SINTETICA"} />,
      children: [
        {
          path: "/filtrarconsultasintetica",
          element: <RelatorioConsultaSintetica />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"RELATORIO_APURACAO_BILHETE"} />,
      children: [
        {
          path: "/filtrarapuracaobilhete",
          element: <RelatorioApuracaoBilhete />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"USUARIO_VISUALIZAR"} />,
      children: [
        {
          path: "/usuario",
          element: <Usuario />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"REGISTRO_VISUALIZAR"} />,
      children: [
        {
          path: "/registro",
          element: <Registro />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"FRANQUIA_VISUALIZAR"} />,
      children: [
        {
          path: "/franquia",
          element: <Franquia />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"CLIENTE_VISUALIZAR"} />,
      children: [
        {
          path: "/cliente",
          element: <Cliente />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"LEILAO_VISUALIZAR"} />,
      children: [
        {
          path: "/leilao",
          element: <Leilao />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"VEICULO_VISUALIZAR"} />,
      children: [
        {
          path: "/veiculo",
          element: <Veiculo />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"LEILAO_CADASTRAR_LOTE"} />,
      children: [
        {
          path: "/cargaleilao",
          element: <HomePage />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"VEICULO_VENDA_VISUALIZAR"} />,
      children: [
        {
          path: "/veiculovenda",
          element: <VeiculoVenda />,
        },
      ],
    },
    {
      element: (
        <PermissionLayout permission={"VEICULO_TRANSFERENCIA_VISUALIZAR"} />
      ),
      children: [
        {
          path: "/veiculoTransferencia",
          element: <VeiculoTransferenciaPage />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"PERMISSAO_VISUALIZAR"} />,
      children: [
        {
          path: "/permissao",
          element: <Permissao />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={"USERINFO"} />,
      children: [
        {
          path: "/alterarsenha",
          element: <AlterarSenha />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/404",
          element: <Page404 />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "/parametroaplicacao",
          element: <ParametroAplicacao />,
        },
      ],
    },
    {
      element: <PermissionLayout permission={undefined} />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ],
  { basename: "/creditbureau" }
);

const RouterCustom: FunctionComponent = () => {
  return <RouterProvider router={router} />;
};

export default RouterCustom;
