import { Panel } from "primereact/panel";
import { Card } from "../../../../components/Card";
import { openPDF } from "../../../../utils/open_pdf_base64";

export const SisCsvPage = (data: any) => {
  const RenderPDF = (data: any) => {
    return (
      <button
        onClick={() => openPDF(data?.base64)}
        style={{
          background: "transparent",
          border: "none",
          textDecoration: "underline",
          cursor: "pointer",
          color: "blue",
        }}
      >
        Clique para visualizar
      </button>
    );
  };

  return (
    <Panel
      header="Laudo de Vistoria de Veículos Automotores"
      toggleable
      style={{ width: "98%" }}
    >
      {data && (
        <>
          {data?.BASE64_PDF_1 && (
            <Card.Item
              label="Laudo PDF 01"
              value={<RenderPDF base64={data?.BASE64_PDF_1} />}
              style={{ backgroundColor: "#f0f0f0" }}
            />
          )}

          {data?.BASE64_PDF_2 && (
            <Card.Item
              label="Laudo PDF 02"
              value={<RenderPDF base64={data?.BASE64_PDF_2} />}
            />
          )}

          {data?.BASE64_PDF_3 && (
            <Card.Item
              label="Laudo PDF 03"
              value={<RenderPDF base64={data?.BASE64_PDF_3} />}
              style={{ backgroundColor: "#f0f0f0" }}
            />
          )}
        </>
      )}

      {!data && <p>Nada consta</p>}
    </Panel>
  );
};
